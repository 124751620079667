<script setup lang="ts">
import {useElementVisibility} from "@vueuse/core"

const props = defineProps<{
  text: string,
}>()

const el = ref<HTMLElement|null>(null)

const tokenElements = computed(() => {
  return el.value?.querySelectorAll('[data-token]') ?? []
})

const words = computed(() => {
  return props.text.split(' ')
})

const targetIsVisible = useElementVisibility(el)

const resetClasses = ['!translate-y-0', '!rotate-0']

watchEffect(() => {
  targetIsVisible.value ? play() : rewind()
})

function play() {
  tokenElements.value.forEach((element, index) => {
    setTimeout(() => {
      element.classList.add(...resetClasses)
    }, (index + 1) * 36)
  })
}

function rewind() {
  tokenElements.value.forEach((element, index) => {
    element.classList.remove(...resetClasses)
  })
}

function replay() {
  rewind()
  play()
}
</script>

<template>
  <span @click="replay" class="flex flex-wrap" ref="el" :aria-label="text">
    <span v-for="(word, index) in words" class="inline-flex overflow-hidden">
      <span data-token class="transition-transform ease-wavy translate-y-full rotate-45" v-for="(token, index) in word.split('')" :key="index" v-html="token" />
      <template v-if="index < words.length - 1">&nbsp;</template>
    </span>
  </span>
</template>