<script setup lang="ts">
import { useElementVisibility } from '@vueuse/core'

interface Props {
  sources: {src: string, type: string}[]
  poster?: string
}

const props = withDefaults(defineProps<Props>(), {})

const el = ref<HTMLVideoElement|null>(null)
const elementIsVisible = useElementVisibility(el)

const unwatch = watch(elementIsVisible, (isVisible) => {
  if (isVisible) {
    setSources()
    unwatch()
  }
})

const sourcesSet = ref(false)

function setSources() {
  if (sourcesSet.value) {
    return
  }

  sourcesSet.value = true

  if (el.value) {
    _setSources()
    return
  }

  onMounted(() => {
    _setSources()
  })
}

function _setSources() {
  el.value!.querySelectorAll('source[data-src]').forEach((source) => {
    source.setAttribute('src', source.getAttribute('data-src')!)
    source.removeAttribute('data-src')
  })
  el.value!.load()
}

defineExpose({
  setSources,
  videoElement: el,
})
</script>

<template>
  <video ref="el" autoplay muted loop playsinline :poster="poster" class="w-full">
    <source v-for="source in sources" :key="source.src" :data-src="source.src" :type="source.type" />
  </video>
</template>