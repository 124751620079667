<script setup lang="ts">
import Lottie, {AnimationItem} from 'lottie-web'
import { useElementVisibility } from '@vueuse/core'

interface Props {
  path: string
  loop?: boolean
  autoplay?: boolean

  loadOn?: 'appear'|'mount'|null
}

const props = withDefaults(defineProps<Props>(), {
  loop: true,
  autoplay: true,
  loadOn: 'appear',
})

const el = ref<HTMLDivElement|null>(null)
let lottieAnimation: AnimationItem|null = null
const elementIsVisible = useElementVisibility(el)

function load() {
  lottieAnimation = Lottie.loadAnimation({
    container: el.value!,
    loop: props.loop,
    autoplay: props.autoplay,
    path: props.path,
  })
}

onMounted(() => {
  if (props.loadOn === 'mount') {
    load()
  }
})

const unwatch = watch(elementIsVisible, (isVisible) => {
  if (isVisible && props.loadOn === 'appear') {
    load()
    unwatch()
  }
})

onBeforeUnmount(() => {
  lottieAnimation?.destroy()
})

defineExpose({
  getAnimation: () => lottieAnimation,
})
</script>
<template>
  <div ref="el"></div>
</template>