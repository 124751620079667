<script setup lang="ts">
const props = defineProps<{
  icon?: string | null
  text: string
}>()
</script>

<template>
  <p class="md:text-xl mb-4">
    <ShowcasePartsRevealPill v-bind="props" />
  </p>
</template>