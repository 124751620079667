<script setup lang="ts">
import {useDomainDataStore} from '~/stores/domain'

const {domainData} = useDomainDataStore()
</script>
<template>
  <div class="rounded-t-[6%_11%] rounded-b-[6%_11%] overflow-hidden">
    <div class="mx-[-1.5%] my-[-3%] mb-[-3.5%]">
      <AtomsLazyVideo class="block aspect-[768/480]"
                      :poster="`/assets/showcase/video/tools/flip-${domainData.domain}.png`"
                      :sources="[
                                    {src: `/assets/showcase/video/tools/flip-${domainData.domain}.webm`, type: 'video/webm'},
                                    {src: `/assets/showcase/video/tools/flip-${domainData.domain}.mp4`, type: 'video/mp4'},
                                    ]"/>
    </div>
  </div>
</template>